.donationEditForm {
    & .row {
        margin-bottom: 10px;        
    }

    & label {
        margin-bottom: 4px;
    }
    & h5 {
        margin-bottom: 4px;
    }

    & img.active {
        outline: 4px solid rgba(100, 65, 164, .4);
        outline-offset: 1px;
    }

    & .versecollection {
      
      
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
      
    }
    
}

.donationInfo {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: 10px 0;

}