$success-alt: #228C4E;

.filters {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 20px;
  
    & .inline-elements {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 20px;
    }
}

.extref-col {
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    &:hover {
        cursor: pointer;
    }
}

.no-wrap {
    white-space: nowrap;
}

.button-container {    
    display: flex;
    gap: 8px;
    flex-direction: row;
    
}

$memcolor: #228C4E;
$celebcolor: #652f8b;
$onetimecolor: #d64387;

.donation {
    &-symbol {
        border: solid 2px #333;
        color: #333;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        margin: auto;
    }

    &-0 {
        color: $memcolor;
        border-color: $memcolor;
    }

    &-1 {
        color: $celebcolor;
        border-color: $celebcolor;
    }

    &-2 {
        color: $onetimecolor;
        border-color: $onetimecolor;
    }
}

.success-alt {
    background-color: $success-alt !important;
}


.action-btns {
    gap: 30px;
}